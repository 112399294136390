.close-selected-tab {
    width: 50px !important;
    height: 48px;
    border-radius: 100px;
    margin: 10px;
}

.open-selected-tab {
    border-radius: 0px 30px 30px 0px;
}
/* @media (max-width: 767px) {
    .side-panel {
      display: none;
    }
  } */