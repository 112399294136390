body {
    margin: 0;
    padding: 0;
}
.aligner {
    display: flex;
    align-items: center;
    min-height: 90vh;    
    justify-content: center;
}
.card{background-color: transparent;border:0px;width:100%;}
.card .logo{padding-bottom: 40px;}
.card-title{    
font-weight: bold;
font-size: 20px; 
padding-bottom: 30px;
padding-top: 30px;
margin: 0px;
color: #333;
}
.btn-custom{
    background: #fcb116;
    border: 1px solid #fcb116;
    padding: 6px 25px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-custom{
    padding: 8px 25px;
    font-size: 14px;
    border-radius: 2px;
    color: #fff;
    width:100%;
}
.blueBtn{
    background: #20abcb;
    border: 1px solid #20abcb;
}
.orangeBtn{
    background: #EC7C25;
    border: 1px solid #EC7C25;
}
.btn-custom:hover{
    background: transparent;
        box-shadow: none;
}
.custom-control-label:after, .custom-control-label:before{
        width: 16px;
    height: 16px;
}
.keepfgt{font-size: 12px;color:#231f20;font-weight: bold;
    line-height: 20px;}
.keepfgt a{color:#231f20;font-weight: bold;}
.keenlabel{padding-left: 10px;}
.custom-control-label:before {background-color: transparent;border:1px solid #231f20;}
.form-section .form-control{
  /*border:1px solid #5B5B5B;*/
  border-radius: 8px;
  background-color:#f4f4f47d;
}
.form-section .form-group {
    margin-bottom: 8px;
}
.captcha .g-recaptcha div {
    width: auto !important;
    height: auto !important;
}
.form-section a{color:#231f20;}
.captcha {margin-top: 20px;}

.darkblack{color: #333;}
.copywrite a{color: #333;text-decoration: underline;}
.copywrite{margin-top: 15px;margin-bottom: 15px;}
.submitwrp{margin-top: 20px;margin-bottom: 20px !important;}
.btn-custom:focus {outline: none !important;box-shadow: none !important;}
.card-inner{text-align: center;}
@media only screen and (min-width: 768px) { 
  .card-inner{width:400px;}
  form.form-section {padding: 0px 48px;}
  body {
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (max-width: 767px) { 
  .aligner {
    display: block;
  }
  .section-one{
    width:100% !important;
    height: 50vh !important;
  } 
  .section-two{
    width:100% !important;
  }
  .inner-box{
    top:5% !important;
  }
  .inner-box-heading{
    top:10% !important;
  }
  .heading-style{
    font-size:18px;
    line-height:12px;
  }
  .card-inner{padding-top: 20px;padding-bottom: 20px;margin: 0px auto;}

  /*To set the form width as per design on mobile view*/
.form-content{
  /*width:auto !important;*/
  width: 75% ;/*Uul tag making issue*/
}  

/*upper right got an account section*/
.nav-text{
    padding: 10px 0px;
    text-align: center;
}
}

/*Custom CSS added for new design*/
.section-one{
    width: 40%;
    background: #FFF8F6;
    height: 100vh;
    float: left;
    text-align: center;
}
.section-two{
  width:60%;
  background: #fff;
  float:right;
  height: 100vh;
}
.myBlue{
  color:#1BA1CB;
}
.nav-text{
    padding: 10px 10px;
    text-align: right;
    font-size: 14px;
}
/*To make the left column layout flex*/
.content-container{
    display: flex;
    justify-content: center;
  }
.inner-box{
    position: relative;
    top: 20%;
  }

.inner-box-heading{
    position: relative;
    top: 25%;
}
.align-items-end{
      /* -webkit-align-content: center;  */
    -ms-flex-align: end!important;
    align-items: center!important;
}  

form.form-section {
    padding: 0px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control {
    border-radius: 2px !important;
    height: 50px !important;
    }

.form-section .form-group {
    margin-bottom: 25px;
}
/*custom class created*/
.form-label{
  float:left;
  font-size:18px;
  color:#5B5B5B;
  font-weight: 300
}
/*To set the form width as per design on web view*/
.form-content{
  width:60%;
  text-align: center;
}

.card-title-desc{
  font-size:14px;
}
/*Css for the techeela moto horizontal line*/
.custom-register-line{
  width: 70px;
  border: 1px solid #5B5B5B;
  background: #5b5b5b;
}
.registered-view-container{
  text-align:center;
  top:10%;
  position:relative;
}
.showPassword{
  /* margin-left: 46rem !important; */
  float: right;
}

.showCPassword{
  float: right;
  /* margin-left: 40rem !important; */
}
.resetformcontainer{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.resetformcontainer .form-group{
  width: 60%;
}
.form-group {
  margin-bottom: 1rem;
}
.form-label {
  float: left;
  font-size: 18px;
  color: #5B5B5B;
  font-weight: 300;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control {
  border-radius: 2px !important;
  height: 50px !important;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.align-items-end {
  /* -webkit-align-content: center; */
  align-items: center!important;
}
.flex-column {
  text-align: center;
}
.flex-column {
  -ms-flex-direction: column!important;
  flex-direction: column!important;
}
.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}
.registered-view-container {
  text-align: center;
  top: 10%;
  position: relative;
}
.card-title {
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
  margin: 0px;
  color: #333;
}
.card-title-desc {
  font-size: 14px;
  text-align: center;
  padding: 10px 0px;
}
.card {
  background-color: transparent;
  border: 0px;
  width: 100%;
}
.registered-view-container {
  text-align: center;
  top: 10%;
  position: relative;
}
.section-two {
  width: 60%;
  background: #fff;
  float: left;
  height: 100vh;
}
.section-one {
  width: 40%;
  background: #FFF8F6;
  height: 100vh;
}
.resetformcontainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
#show-password-ref {
  color: #1BA1CB;
}
html input[disabled] {
  cursor: not-allowed;
}