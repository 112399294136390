/* login.css */
body{
    padding: 0px;
    margin: 0px;
  }
  .aligner {
      display: flex;
      align-items: center;
      min-height: 80vh;          
      justify-content: center; 
  }
  /* .logo{padding-bottom: 40px;} */
  
  .card{background-color: transparent;border:0px;}/* width changed 100% to auto*/
  /* .card .logo{padding-bottom: 40px;} */
  /* .card-title{    
  font-size: 30px;
  padding-bottom: 30px;
  margin: 0px;
  color: #5B5B5B;
  } */
    .login-copywrite{
      text-align: center;
      font-size: 13px;
  }
  .btn-custom{
      background: #EC7C25 !important;
      border: 1px solid #fcb116;
      padding: 6px 25px;
      font-size: 18px;
      border-radius: 2px;
      width:100%;
      font-weight:700;
  }
  .btn-custom{
      padding: 8px 25px;
      font-size: 18px;
      border-radius: 2px;
      color: #fff;
      font-weight:700;
  }
  .blueBtn{
      background: #20abcb;
      border: 1px solid #20abcb;
  }
  .orangeBtn{
      background: #EC7C25 !important;
      border: 1px solid #fcb116 !important;
  }
  .blackBtn{
      background: #5B5B5B;
      border: 1px solid #5B5B5B;
  }
  .custom-control-label:after, .custom-control-label:before{width: 16px;height: 16px;}
  
  .darkblack{color: #333;}  
  .copywrite a{color: #1BA1CB;text-decoration: underline;}
  .copywrite{margin-top: 15px;margin-bottom: 15px;}
  .keepfgt{font-size: 14px;color:#231f20;font-weight: bold;line-height: 20px;float:left;}
  .keepfgt a{color:#1BA1CB;font-weight: bold;}
  .keenlabel{padding-left: 10px;}
  .custom-control-label:before {background-color: transparent;border:1px solid #231f20;}
  /*.form-section .form-control{border:1px solid #5B5B5B;background-color: #f4f4f47d}*/ /*Css commented to remove the border from input field*/
  .form-section .form-group {margin-bottom: 8px;}
  .btn-custom:focus {outline: none !important;box-shadow: none !important;}
  .btn-custom:hover{background: transparent !important;box-shadow: none !important;}
  .keepinner{margin-top: 15px;margin-bottom: 25px !important;}
  .card-inner{text-align: center;}
  @media only screen and (min-width: 768px) { 
    .card-inner{width:500px;} /*width changed from 350px 500px */
    form.form-section {padding: 0px 30px;}
    body {
      background: #fff;
      /*no-repeat center center fixed;*/
      height: 100%;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
  @media only screen and (max-width: 767px) { 
    .card-inner{padding-top: 20px;padding-bottom: 20px;margin: 0px auto;}  
  }
  
  form.form-section {
      padding: 0px;
  }
  .form-control {
      border-radius: 2px !important;
      height: 50px !important;
      }
  
  .form-section .form-group {
      margin-bottom: 25px;
  }
  /*custom class created*/
  .form-label{
    float:left;
    font-size:18px;
    color:#5B5B5B;
    font-weight: 300
  }
  /*custom class created for show password*/
  .Show-Password{
    float:right;
    font-size:18px;
    color:#5B5B5B;
    font-weight: 300;;
  }
  /*custom class created for custom border line*/
  .custom-border-line{
    border:solid #5B5B5B;
    border-width:0px 0px 1px 0px;
  }
  /*custom class for the copyright text center alignmnent*/
  .custom-center{
    text-align:center;
  }
  
  label#showPassword{
    float:right;
  }
  
  
  
  .Login_card{    
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 30px;
    /* padding-top: 30px; */
    margin: 0px;
    color: #333;
  }
  
  .custom-space{
    margin:10px 0px;
  }
  .btn-link{
    color:#1BA1CB;
    font-weight:700;
  }
  /*Css for the show password anchor(Change cursor style*/
  #show-password-ref:hover{
    cursor:pointer;
  }
  
  #show-password-ref{
    color:#1BA1CB;;
  }
  
  .loginContainer{
    margin-top: 5rem;
  }
  
  
  .btn.focus, .btn:focus, .btn:hover {
      color: #333 !important;
      text-decoration: none !important;
  }
  
  .invalid-feedback{
    display: none;
  }
  .centerAlignDiv{
    justify-content: center;
    align-items: center;
  }
  
  .restButton{
    float: right;
  }
  
  .btn-link-page{
    color:#1BA1CB;
    font-weight:700;
    /* padding: 8px 25px; */
      font-size: 14px;
      border-radius: 2px;
  
  }